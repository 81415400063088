require('dotenv').config({
  path: `.env`,
})

const contentfulConfig = {
  spaceId: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_DELIVERY_API,
  environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  host: process.env.GATSBY_CONTENTFUL_HOST,
}

module.exports = {
  siteMetadata: {
    title: 'Ayzenberg Group',
  },
  pathPrefix: '/spacecamp',
  plugins: [
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        // The property ID; the tracking code won't be generated without it
        trackingId:
          process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID || 'UA-168560898-1',
        head: true,
        // anonymize: true,
        // respectDNT: true,
        exclude: ['/preview/**'],
        pageTransitionDelay: 0,
      },
    },
    'gatsby-plugin-react-helmet',
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-breadcrumb`,
      options: {
        defaultCrumb: {
          // location: required and must include the pathname property
          location: {
            pathname: '/',
          },
          // crumbLabel: required label for the default crumb
          crumbLabel: 'Home',
          // all other properties optional
          crumbSeparator: ' / ',
        },
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `space.camp`,
        short_name: `space.camp`,
        start_url: `/`,
        background_color: `#0072ce`,
        theme_color: `#ffffff`,
        display: `standalone`,
        cache_busting_mode: 'none',
        icon: `src/assets/spacecamp_PWA_512x512.jpg`,
        icon_options: {
          purpose: `maskable`,
        },
      },
    },
    {
      resolve: `gatsby-plugin-offline`,
      options: {
        workboxConfig: {
          globPatterns: ['**/*'],
        },
      },
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: true,
        fileName: false,
      },
    },
    {
      resolve: `gatsby-source-contentful-locales`,
      options: {
        spaceId: contentfulConfig.spaceId,
        accessToken: contentfulConfig.accessToken,
      },
    },
    {
      resolve: 'gatsby-source-contentful',
      options: {
        // may add back in later, when bugs are fixed
        // richText: {
        //   resolveFieldLocales: true,
        // },
        ...contentfulConfig,
      },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName:
          process.env.GATSBY_AWS_TARGET_BUCKET_NAME || 'backup-name-1',
        acl: null,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'assets',
        path: `${__dirname}/src/assets`,
      },
    },
    {
      resolve: 'gatsby-plugin-transition-link',
      options: {
        injectPageProps: false,
      },
    },
  ],
}
